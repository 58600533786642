import request from "../request";
import { AxiosResponse } from "axios";
import {
  IPaginatedPaylod,
  IPaginatedResponse,
} from "../../../../shared/interfaces/request.interface";
import {
  IParamsGetExams,
  IResponseExams,
  IResponseExamsFiles,
} from "../../../../shared/interfaces/exams.interface";

const getExams = ({
  cpf,
  startDate,
  endDate,
  statusExam,
  userName,
  page = 1,
  itemsPerPage = 10,
}: IPaginatedPaylod & IParamsGetExams): Promise<
  AxiosResponse<IPaginatedResponse<IResponseExams>>
> => {
  const config = {
    params: {
      cpf,
      startDate,
      endDate,
      statusExam,
      userName,
      page,
      itemsPerPage,
    },
  };

  return request.get("/exams", config);
};

const getExamsFiles = (
  userId: string
): Promise<AxiosResponse<IResponseExamsFiles>> => {
  return request.get(`/exams/exams-files/${userId}`);
};

export default {
  getExams,
  getExamsFiles,
};

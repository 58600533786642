import request from "../request";
import { AxiosResponse } from "axios";
import { IPaginatedResponse } from "../../../../shared/interfaces/request.interface";
import {
  IParamsCreateProduct,
  IParamsCreateProductMetaData,
  IParamsGetAllProducts,
  IProductItem,
  IProductsForSale,
  IResponseCreateProductMetaData,
  IResponseProductMetaData,
  IResponseProducts,
} from "../../../../shared/interfaces/products.interface";

const createProduct = (
  body: IParamsCreateProduct
): Promise<AxiosResponse<IProductsForSale>> => {
  return request.post(`/products`, body);
};

const getProductMetaData = (): Promise<
  AxiosResponse<IResponseProductMetaData>
> => {
  return request.get(`/product-metadata`);
};

const createProductMetaData = (
  body: IParamsCreateProductMetaData
): Promise<AxiosResponse<IResponseCreateProductMetaData>> => {
  return request.post(`/product-metadata`, body);
};

const getAllProducts = ({
  page,
  itemsPerPage,
  searchTerm,
  brand,
  group,
  provider,
}: IParamsGetAllProducts): Promise<
  AxiosResponse<IPaginatedResponse<IProductItem>>
> => {
  const config = {
    params: { page, itemsPerPage, searchTerm, brand, group, provider },
  };
  return request.get(`/products/all`, config);
};

const deleteProduct = (
  id: string,
  body: { deleted: boolean }
): Promise<AxiosResponse<{ message: string }>> => {
  return request.put(`/products/${id}`, body);
};

const editProduct = (
  productId: string,
  body: IProductItem
): Promise<AxiosResponse<IProductsForSale>> => {
  return request.put(`/products/${productId}`, body);
};
const createBulkProducts = (data: FormData) => {
  return request.post(`/products/bulk-create `, data);
};

export default {
  getAllProducts,
  deleteProduct,
  editProduct,
  createProduct,
  getProductMetaData,
  createProductMetaData,
  createBulkProducts,
};

import { useLayoutEffect, useMemo, useState } from 'react';
import { TUserRoles } from '../../../../shared/interfaces/userInfo.interface';
import { TUser } from '../../../../types/user';

function useUser() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isRoot, setIsRoot] = useState<boolean>(false);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isDoctor, setIsDoctor] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<TUser | undefined>(undefined);
  const currentPath = useMemo(() => window?.location?.pathname, []);

  useLayoutEffect(() => {
    const data = JSON.parse(window.localStorage.getItem('user'));
    // getLoggedUser()

    if (!data) {
      return;
    }

    const { root } = data;
    const roles: TUserRoles[] = data.roles as TUserRoles[];

    setUserInfo(data);

    if (roles?.includes('admin')) {
      setIsAdmin(true);
    }
    if (roles?.includes('employee')) {
      setIsEmployee(true);
    }
    if (roles?.includes('doctor')) {
      setIsDoctor(true);
    }

    if (root) {
      setIsRoot(true);
    }
  }, []);

  return { isAdmin, isRoot, isEmployee, userInfo, isDoctor };
}

export default useUser;

import { TUserRoles } from "../../../shared/interfaces/userInfo.interface";

enum EKeyCookie {
  ACCESS_TOKEN = "access_token",
  USER = "user",
  ROLES = "roles",
  VIEW_TYPE = "view_type",
  COMPANY = "company",
}

export const isAuthenticated = (): boolean => {
  return Boolean(getAccessToken() && getCurrentUser());
};

export const setAccessToken = (accessToken: string) => {
  setCookie(EKeyCookie.ACCESS_TOKEN, accessToken);
};

export const getAccessToken = (): string | null => {
  return getCookie("access_token");
};

export const setCompany = (companyId: string) => {
  setCookie("company", companyId);
};

export const getCompany = (): string | null => {
  return getCookie("company");
};

export const setCurrentUser = (user: any): void => {
  const roles = user.roles;
  delete user.roles;
  setCookie("user", JSON.stringify(user));
  setCookie("roles", JSON.stringify(roles));
};

export const getCurrentUser = (): object | null => {
  try {
    return JSON.parse(getCookie("user"));
  } catch (err) {
    return null;
  }
};

export const getRoles = (): string | [] => {
  try {
    const user = getCurrentUser();
    const [company] = user ? user["companies"] : [];
    const { roles } = company ?? { roles: [] };

    return roles;
  } catch (err) {
    return [];
  }
};

export const clearAuthCookies = () => {
  eraseCookie("access_token");
  eraseCookie("user");
  eraseCookie("roles");
  eraseCookie("view_type");
  eraseCookie("company");
};

export const setViewType = (typeView: TUserRoles): void => {
  setCookie("view_type", typeView);
};

export const getViewType = (): TUserRoles => {
  return getCookie("view_type") as TUserRoles;
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "expires=" + date.toUTCString();
  }
  document.cookie = `_bv_${name}=${value || ""}; path=/; ${expires}`;
};

export const getCookie = (name: string): string => {
  const nameEQ = `_bv_${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};

export const eraseCookie = (name: string): void => {
  document.cookie = `_bv_${name}=; path=/;`;
};

import request from "../request";
import { AxiosResponse } from "axios";
import { IReportAdminResponse } from "../../../../shared/interfaces/reportAdmin.interfaces";

const getReport = async ({
  companyId = undefined,
  startDate = undefined,
  endDate = undefined,
}): Promise<AxiosResponse<IReportAdminResponse>> => {
  const config = {
    params: { companyId, startDate, endDate },
  };
  return request.get(`/admin/rh/report`, config);
};

export default {
  getReport,
};

import { AxiosResponse } from "axios";
import request from "../request";
import { TOptions } from "../../../../shared/interfaces/form.interface";
import {
  IExtendedPaginatedResponseBusinessUnitGetAlll,
  IExtendedPaginatedResponseCompanyGetAll,
  IPayloadBusinessUnitGetAll,
  IPayloadCompanyGetAll,
  IPaylodBusinessUnitUpdade,
  IPaylodUpdateCompanyBenefit,
  IResponseBusinesUnitsGetOne,
  IResponseBusinessUnitGetAll,
  IResponseCompaniesWithBusinessUnitsGetAll,
  IResponseCompanyGetAll,
  IResponseGetOneCompanyBenefit,
} from "../../../../shared/interfaces/company.interface";
import {
  IPaginatedPaylod,
  IPaginatedResponse,
} from "../../../../shared/interfaces/request.interface";

const companyGetAll = async ({
  cnpj,
  companySizeRange,
  companyType,
  corporateName,
  excessEmployees,
  planId,
  status,
  typeOfSale,
  itemsPerPage = 10,
  page,
  fields,
}: IPayloadCompanyGetAll): Promise<
  AxiosResponse<IExtendedPaginatedResponseCompanyGetAll<IResponseCompanyGetAll>>
> => {
  const config = {
    params: {
      cnpj,
      companySizeRange,
      companyType,
      corporateName,
      excessEmployees,
      planId,
      status,
      typeOfSale,
      itemsPerPage,
      page,
      fields,
    },
  };

  return request.get("/companies/benefits", config);
};

const companyGetOne = async (
  id: string
): Promise<AxiosResponse<IResponseGetOneCompanyBenefit>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request.get(`/companies/benefits/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

const benefitUpdate = async (id: string, body) => {
  // const response = await request.put(`/company/id/${id}`, body)
  return request.patch(`/companies/benefits/${id}`, body);
};

const companyCreate = async (body) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await request.post("/companies/benefits", body);
    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Busca todas as filiais ativas da empresa tipo ótica do usuário logado.
 */
const companyGetBranches = async ({
  companyType,
  branchStatus,
}: {
  companyType: "benefit" | "optics";
  branchStatus: "active" | "inactive";
}): Promise<
  AxiosResponse<{
    branches: TOptions[];
    totalBranches: number;
  }>
> => {
  const config = {
    params: {
      companyType,
      branchStatus,
    },
  };

  return request.get(`/company/branches`, config);
};

const businessUnitGetAll = async ({
  page = 1,
  itemsPerPage = 10,
  categoryId,
  rootCompanyId,
  corporateName,
  status,
}: IPayloadBusinessUnitGetAll): Promise<
  AxiosResponse<
    IExtendedPaginatedResponseBusinessUnitGetAlll<IResponseBusinessUnitGetAll>
  >
> => {
  const config = {
    params: {
      page,
      itemsPerPage,
      categoryId,
      rootCompanyId,
      corporateName,
      status,
    },
  };

  return request.get("/companies/business-unit", config);
};

const companiesWithBusinessUnitsGetAll = ({
  companyId = undefined,
  companyName = undefined,
  page = 1,
  itemsPerPage = 30,
  fields,
  listBusinessUnitsPlanName,
}: {
  companyId?: string;
  companyName?: string;
  fields?: string;
  listBusinessUnitsPlanName?: "true" | undefined;
} & IPaginatedPaylod): Promise<
  AxiosResponse<IPaginatedResponse<IResponseCompaniesWithBusinessUnitsGetAll>>
> => {
  const config = {
    params: {
      page,
      itemsPerPage,
      companyId,
      companyName,
      fields,
      listBusinessUnitsPlanName,
    },
  };

  return request.get("companies/with-business-units", config);
};
const companyWithBusinessUnitsGetOne = ({
  fields = undefined,
}: {
  fields?: string;
}): Promise<AxiosResponse<IResponseCompaniesWithBusinessUnitsGetAll>> => {
  const config = {
    params: {
      fields,
    },
  };

  return request.get("company/with-business-units", config);
};

const businessUnitCreate = (body): Promise<AxiosResponse<{ id: string }>> => {
  return request.post("/companies/business-unit", body);
};

const businessUnitGetOne = (
  id: string
): Promise<AxiosResponse<IResponseBusinesUnitsGetOne>> => {
  return request.get("/companies/business-unit/" + id);
};

const businessUnitUpdate = (
  id: string,
  body: IPaylodBusinessUnitUpdade
): Promise<AxiosResponse<{ id: string; name: string }>> => {
  return request.patch("/companies/business-unit/" + id, body);
};

const opticsCreate = (body): Promise<AxiosResponse<{ id: string }>> => {
  return request.post("/companies/optics", body);
};
const partnerCreate = (body): Promise<AxiosResponse<{ id: string }>> => {
  return request.post("/companies/partners", body);
};
const partnerGetAll = ({
  itemsPerPage = 10,
  page = 1,
  companyName,
}: IPaginatedPaylod & { companyName?: string }): Promise<
  AxiosResponse<IPaginatedResponse<{ id: string; corporateName: string }>>
> => {
  const config = {
    params: {
      page,
      itemsPerPage,
      companyName,
    },
  };

  return request.get("/companies/partners", config);
};

export default {
  companyGetAll,
  companyGetOne,
  benefitUpdate,
  companyCreate,
  companyGetBranches,
  businessUnitGetAll,
  companiesWithBusinessUnitsGetAll,
  businessUnitCreate,
  businessUnitGetOne,
  businessUnitUpdate,
  opticsCreate,
  partnerGetAll,
  partnerCreate,
  companyWithBusinessUnitsGetOne,
};

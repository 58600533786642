import { AxiosPromise, AxiosResponse } from 'axios';
import request from '../request';
import { IFormsTest } from '../../../../shared/interfaces/formTest.interface';

/**
 *
 * @param id do usuario
 * @returns retorna todos em testes feito pelo usuario com o id acima
 */
const getByPatientId = async (id: string): Promise<AxiosResponse<any>> => {
  try {
    const response = await request.get('/form', { params: { id } });
    return response;
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};

/**
 *
 * @param id id de um teste que queira buscar
 * @returns  retorna um teste específico
 */
const getById = async (id: string): Promise<AxiosResponse<IFormsTest<any>>> => {
  try {
    const response = await request.get(`/form/${id}`);
    return response;
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};

const create = async (body: object) => {
  try {
    const response = await request.put('/form', body);
    return response;
  } catch (err) {
    throw Error(err);
  }
};

const destroyByPatient = async (patientId: string) => {
  return await request.delete(`/form/clear-by-patient/${patientId}`);
};

export default {
  getById,
  getByPatientId,
  create,
  destroyByPatient,
};

import axios from 'axios';
import BVUtils from '@bv/utils';
import { UserUnit } from '../../../../../types/v2/user';

export const requestV2 = axios.create({
  baseURL: process.env.BV_API_URL,
});

requestV2.interceptors.request.use(function (config) {
  const ACCESS_TOKEN: string | null = BVUtils.cookies.getAccessToken();
  const SESSION_UNIT_ROLE: string | null = BVUtils.session.getUserViewTypeUtil();
  const SESSION_UNIT_ID: UserUnit | null = BVUtils.session.getUnitInSessionUtil();

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  if (SESSION_UNIT_ID) {
    Object.assign(config.headers, {
      'X-Active-Unit-Id': SESSION_UNIT_ID._id,
    });
  }

  if (SESSION_UNIT_ROLE) {
    Object.assign(config.headers, {
      'X-Active-Unit-Role': SESSION_UNIT_ROLE,
    });
  }

  return config;
});

requestV2.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    if (err.response && [401, 403].includes(+err.response.status)) {
      BVUtils.cookies.clearAuthCookies();
      window.localStorage.clear();
      window.location.href = '/login';
    }

    if (err?.response?.data?.messages) {
      BVUtils.request.requestErrorToastHandler(err.response.data.messages);
      return Promise.reject(err.response.data.messages);
    }

    if (err?.response?.data?.message) {
      BVUtils.request.requestErrorToastHandler(err.response.data.message);
      return Promise.reject(err.response.data.message);
    }

    return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
  },
);

import { useMemo } from "react";
import { IPaginatedResponse } from "../../../../shared/interfaces/request.interface";

export default function useSumItemsTable<T>(
  data: IPaginatedResponse<T> | undefined,
  page: number
) {
  const sumItems: number = useMemo(() => {
    if (!data) return 0;
    let total = (page == 0 ? 1 : page) * data?.itemsPerPage;
    if (page == data?.totalPages) {
      const sumDifference = total - data?.totalItems;
      total = total - sumDifference;
    }
    return total;
  }, [data, page]);

  return {
    sumItems,
  };
}

import { AxiosResponse } from 'axios';
import request from '../request';
import { IPaginatedPaylod, IPaginatedResponse } from '../../../../shared/interfaces/request.interface';
import { IUsersPayload, IUsersResponse } from '../../../../shared/interfaces/user.interface';
import { IUsersBusinessUnitsResponse } from '../../../../shared/interfaces/businessUnits.interface';
import { IUserInvite } from '../../../../shared/interfaces/invite.interface';
import { IUsersCompaniesBenefitsResponse } from '../../../../shared/interfaces/companiesBenefits.interface';

export type TCreateUserResponse = {
  id: string;
  cpf: string;
  crm: string | null;
  acceptedTerms: boolean;
  address: {
    city?: string;
    complement?: string;
    neighbourhood?: string;
    number?: string;
    state?: string;
    street?: string;
    zipCode?: string;
  };
  birthdate: string;
  cellphone?: string | null;
  companies: {
    email?: string;
    id: string;
    name: string;
    roles: ('admin' | 'employee' | 'doctor')[];
    status: 'active' | 'inactive';
    type: 'benefit' | 'optics' | 'businessUnit' | 'occupational' | 'partner';
  }[];
  companiesHistory: {
    oldCompanyId?: string | null;
    newCompanyId: string;
    oldPlan?: {
      id: string;
      modules: string[];
    };
    newPlan?: {
      id: string;
      modules: string[];
    };
    email: string;
    status: 'active' | 'inactive';
    createdAt?: string;
  }[];
  createdAt?: string;
  updatedAt?: string;
  emails: string[];
  gender: string;
  name: string;
  pendencies?: 'NONE' | 'TELEMEDICINE';
  root?: boolean;
  searchName?: string;
  jobPosition?: string;
};

const getAll = async ({
  companyId,
  role,
  searchTerm,
  itemsPerPage = 10,
  page = 1,
}: IPaginatedPaylod & IUsersPayload): Promise<AxiosResponse<IPaginatedResponse<IUsersResponse>>> => {
  const config = {
    params: {
      companyId,
      searchTerm,
      role,

      itemsPerPage,
      page,
    },
  };
  return request.get('/users', config);
};

const getById = async (id) => {
  return request.get(`/users/id/${id}`);
};

const create = async (data): Promise<{ data: { data: { newUser: TCreateUserResponse; accessToken: string } } }> => {
  return request.post('/v2/users', data);
};

const update = async (userId: string, body) => request.put(`/users/id/${userId}`, body);

const updatePass = async (userId: string, body) => request.put(`/users/${userId}/pass-by-admin`, body);

const destroy = async (user) => request.put(`/users/id/${user.id}`, user);

const getAllBusinessUnits = async (
  rootCompanyId: string,
  { itemsPerPage, page, corporateName }: IPaginatedPaylod & { corporateName: string },
): Promise<AxiosResponse<IPaginatedResponse<IUsersBusinessUnitsResponse>>> => {
  const config = {
    params: {
      corporateName,
      itemsPerPage,
      page,
    },
  };

  return request.get(`/users/business-units/${rootCompanyId}`, config);
};

const getInviteByCompanyId = async (companyId: string, { email }: { email: string }): Promise<AxiosResponse<IUserInvite>> => {
  const config = {
    params: {
      email,
    },
  };

  return request.get(`/users/invites/${companyId}`, config);
};

const getAllCompaniesBenefits = async ({
  corporateName,
  itemsPerPage,
  page,
}: IPaginatedPaylod & { corporateName?: string }): Promise<AxiosResponse<IPaginatedResponse<IUsersCompaniesBenefitsResponse>>> => {
  const config = {
    params: {
      corporateName,
      itemsPerPage,
      page,
    },
  };
  return request.get(`/users/companies/benefits`, config);
};

export default {
  create,
  getAll,
  update,
  updatePass,
  destroy,
  getById,
  getAllBusinessUnits,
  getInviteByCompanyId,
  getAllCompaniesBenefits,
};

import request from '../request';

const getStatusPrescription = async () => {
  return request.get('/prescription');
};

const createRenewPrescription = async (body: FormData) => {
  return request.post('/pendencies/prescription-renewal', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export default {
  createRenewPrescription,
  getStatusPrescription,
};

import { AxiosResponse } from "axios"
import { IPayloadCompaniesGet, IResponseCompaniesGet } from "../../../../shared/interfaces/companies.interface"
import request from "../request"

const companiesGet = async ({ searchTerm, companiesTypes, fields, limit }: IPayloadCompaniesGet): Promise<AxiosResponse<IResponseCompaniesGet>> => {
  const config = {
    params: {
      searchTerm,
      companiesTypes,
      fields,
      limit
    },
  }

  return request.get("/companies", config)
}

export default {
    companiesGet,
}
import request from '../request';
import { AxiosResponse } from 'axios';
import { IReportAdminResponse } from '../../../../shared/interfaces/reportAdmin.interfaces';

const getReport = async ({ companyId = undefined, startDate = undefined, endDate = undefined }): Promise<AxiosResponse<IReportAdminResponse>> => {
  const config = {
    params: { companyId, startDate, endDate },
  };
  return request.get(`/v2/users/dashboard`, config);
};

export default {
  getReport,
};


function getAddressByZipCode(cep) {
  const zipCode = cep.replace(/\D/g, '')
  if (!zipCode?.length || zipCode.length !== 8) return

  return fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
    .then(res => res.json())
    .then(data => ({
      zipCode,
      street: data.logradouro,
      neighbourhood: data.bairro,
      city: data.localidade,
      state: data.uf,
    }))
}

export default {
  getAddressByZipCode
}
import { cookiesHelper, api, colors, hooks, format } from './utils';
import * as config from './utils/config';

type TUtilsOld = {
  cookiesHelper: typeof cookiesHelper;
  api: typeof api;
  colors: typeof colors;
  hooks: typeof hooks;
  config: typeof config;
};

const UtilsOld = {
  cookiesHelper,
  api,
  colors,
  hooks,
  config,
  format,
};

export default UtilsOld;

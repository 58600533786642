import request from "../request";
import axios, { AxiosResponse } from "axios";
import { TUser } from "../../../../types/user";

const BASE_URL: string | undefined = process.env.BV_API_URL;

const preLogin = async (email: string, params: string) => {
  const url = `${BASE_URL}/login/pre-login/${email}?${params}`;

  return axios.get(url);
};

const signIn = async (
  username: string,
  password: string | null,
  companyId: string,
  ssoToken: string | null,
  recaptchaToken: string | null
) => {
  const body = { username, password, companyId, ssoToken, recaptchaToken };
  return request.post(`${BASE_URL}/login`, body);
};

const loggedUser = async (): Promise<AxiosResponse<TUser>> => {
  return request.get(`/users/logged`);
};

const preRedefinePassword = async (email: string) => {
  const url = `${BASE_URL}/reset-password`;
  return axios.post(url, { email });
};

const redefinePassword = (
  token: string,
  password: string,
  repeatPassword: string
) => {
  const url = `${BASE_URL}/redefine-password`;
  const body = { token, password, repeatPassword };

  return axios.post(url, body);
};

export default {
  preLogin,
  signIn,
  loggedUser,
  preRedefinePassword,
  redefinePassword,
};

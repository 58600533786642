import axios from 'axios'

const BASE_URL: string | undefined = process.env.BI_API_URL

const request = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'api-key': process.env.BI_APY_KEY,        
    }
})

request.interceptors.response.use(null, function(err) {
    if (err.response && err.response.status === 401) {
        window.location.href = '/login';
    } else if (err.response && err.response.status === 400 && err.response && err.response.data) {
      return Promise.reject(err.response.data);
    } else if (err.response && err.response.status === 422 && err.response.data && err.response.data.errors) {
      const errors = Object.keys(err.response.data.errors).reduce((acc, key) => {
        acc = acc.concat(err.response.data.errors[key]);
        return acc;
      }, []);
      return Promise.reject({ message: errors.join(' | ') });
    }
    return Promise.reject({
      message: 'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.'
    });
})

export default request
import { IAvailableSchedules } from '../../../../shared/interfaces/availableSchedules.interface';
import request from '../requestBI';
import { AxiosResponse } from 'axios';

const getAvailableDays = async (month: number, year: number) => request.get('/availableDays', { params: { month, year } });

const getAvailableSchedules = async (date: string): Promise<AxiosResponse<IAvailableSchedules[]>> =>
  request.get('/availableSchedules', { params: { date } });

const getAppointments = async (patientId: string, params?: object): Promise<AxiosResponse<any>> =>
  request.get(`/${patientId}/appointments`, { params: { params } });

const createAppointment = async (body: object) => request.post('/appointment', body);

const printableFile = (documentId: string) => request.get(`/printable-file/${documentId}`, { responseType: 'blob' });

const updateStatus = async (body: object) => request.put(`/appointments/updateStatus`, body);

export default {
  getAvailableDays,
  getAvailableSchedules,
  getAppointments,
  createAppointment,
  printableFile,
  updateStatus,
};

import {
  CreateResponseDto,
  CreateResponseQuery,
  CreateResponseResponse,
  GetResponseByIdService,
  GetResponsesByPatientIdQuery,
  GetResponsesByPatientIdResponse,
  UpdateResponseDto,
  UpdateResponseQuery,
} from '../../../../../../types/v2/exam';
import { requestV2 } from '../request.v2';

function getResponseByIdService(id: string): Promise<GetResponseByIdService> {
  return requestV2.get(`/v2/responses/${id}`);
}

function getResponsesByPatientIdService(patientId: string, query?: GetResponsesByPatientIdQuery): Promise<GetResponsesByPatientIdResponse> {
  return requestV2.get(`/v2/responses`, { params: { patientId, ...query } });
}

function postCreateResponseService(body: CreateResponseDto, query?: CreateResponseQuery): Promise<CreateResponseResponse> {
  return requestV2.post('/v2/responses', { ...body }, { params: query });
}

function patchUpdateResponseService(id: string, body: UpdateResponseDto, query?: UpdateResponseQuery): Promise<CreateResponseResponse> {
  return requestV2.post(`/v2/responses/${id}`, { ...body }, { params: query });
}

function deleteResponseService(id: string) {
  return requestV2.delete(`/v2/responses/${id}`);
}

export { postCreateResponseService, patchUpdateResponseService, deleteResponseService, getResponseByIdService, getResponsesByPatientIdService };

import { createContext, useContext } from 'react';
import authApi from '../api/auth.api';
import { getCurrentUser, clearAuthCookies } from '../cookiesHelper';
import { ssoSignOut } from './useSSO';

export type TUserAuth = {
  logout: () => void;
  user: object | null;
  loggedUser: object | null;
};

const AuthContext = createContext<TUserAuth | null>({
  user: getCurrentUser(),
  loggedUser: authApi.loggedUser,
  logout: () => {
    ssoSignOut();
    clearAuthCookies();
    localStorage.clear();
  },
});

export default () => {
  return useContext(AuthContext);
};

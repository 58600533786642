import {
    ICreateSale,
    IGetSales,
    IGetStatistics,
    IResponseCreateSale,
    IResponseSalesStatistics,
  } from "../../../../shared/interfaces/sales.interface";
  import request from "../request";
  import { AxiosResponse } from "axios";
  import { IPaginatedResponseV2 } from "../../../../shared/interfaces/request.interface";


  const createSale = (
    body: ICreateSale[]
  ): Promise<AxiosResponse<IResponseCreateSale[]>> => {
    return request.post(`/v2/sales`, body);
  };

  const getSales = ({
    startDate,
    endDate,
    page = 1,
    itemsPerPage = 10,
  }: IGetSales): Promise<
    AxiosResponse<IPaginatedResponseV2<IResponseCreateSale>>
  > => {
    const config = {
      params: { startDate, endDate, page, itemsPerPage },
    };
  
    return request.get("/v2/sales", config);
  };

  const getSalesStatistics = ({
    startDate,
    endDate,
  }: IGetStatistics): Promise<AxiosResponse<IResponseSalesStatistics>> => {
    const config = {
      params: { startDate, endDate },
    };
  
    return request.get(`/v2/sales/statistics`, config);
  };

  export default {
    createSale,
    getSales,
    getSalesStatistics,
  };
import { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import request from "../request";
import {
  IParamsGetReportSalesExportFile,
  IParamsGetReportSalesMade,
  IParamsGetReportSalesRebate,
  IResponseGetReportSalesMade,
  IResponseGetReportSalesRebate,
} from "../../../../shared/interfaces/report.interfaces";

function getReportSalesMade({
  startDate,
  endDate,
  page = 1,
  itemsPerPage = 10,
  branch,
  provider,
  seller,
}: IParamsGetReportSalesMade): Promise<
  AxiosResponse<IResponseGetReportSalesMade>
> {
  const config: AxiosRequestConfig = {
    params: {
      startDate,
      endDate,
      page,
      itemsPerPage,
      branch,
      provider,
      seller,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };
  return request.get("/report/sales/sales-made", config);
}

function getReportSalesRebate({
  branch,
  brands,
  endDate,
  startDate,
  provider,
}: IParamsGetReportSalesRebate): Promise<
  AxiosResponse<IResponseGetReportSalesRebate>
> {
  const config: AxiosRequestConfig = {
    params: { branch, brands, endDate, startDate, provider },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };
  return request.get("/report/sales/rebate", config);
}

function getReportSalesExportFile({
  branch,
  brands,
  startDate,
  endDate,
  provider,
  format,
  reportType,
  seller,
}: IParamsGetReportSalesExportFile): Promise<AxiosResponse<{ url: string }>> {
  const config: AxiosRequestConfig = {
    params: {
      branch,
      brands,
      startDate,
      endDate,
      provider,
      format,
      reportType,
      seller,
      page: 1,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };
  return request.get("report/sales/export-file", config);
}

export default {
  getReportSalesMade,
  getReportSalesRebate,
  getReportSalesExportFile,
};

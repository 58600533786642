/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const ENV = process.env.NODE_ENV

console.log("ENV: ", ENV)
let URL = ""
if (ENV === "dev") {
  URL = `https://dev.beneficiovisao.com.br/login`
}
if (ENV === "prod") {
  URL = `https://app.beneficiovisao.com.br/login`
}
if (ENV === "stage") {
  URL = `https://stage.beneficiovisao.com.br/login`
}
if (ENV === "development") {
  URL = `http://localhost:${9000}/login`
}

export const msalConfig = {
  auth: {
    clientId: null,
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: `${URL}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        console.debug(message)
      },
    },
  },
}

export const loginRequest = {
  scopes: ["User.Read"],
  loginHint: null,
}

export const tokenRequest = {
  scopes: ["User.Read", "Mail.Read"],
  forceRefresh: false, // Set this to 'true' to skip a cached token and go to the server to get a new token
}

export const PLATFORMS = {
  AZURE: "Microsoft",
}
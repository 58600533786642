import request from "../request";

interface opticGeolocationResponse {
  currentPage: number
  itemsPerPage: number
  totalItems: 5
  totalPages: 1
  items: Array<any>
}

const getOpticsNearYou = async (
  params: {
    zipCode: string,
    companyType: string,
    country: string 
    page: number 
    itemsPerPage: number
  }
) => {
  const config = {
    params
  };

  try {
    const response = await request.get("/company/geolocation", config).then( response => response.data )
    return response as opticGeolocationResponse
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};



export default {
  getOpticsNearYou,
};

import request from '../request'

const getCoupon = async () => {
    try {
        const res = await request.get('/coupon')
        const data = res.data

        return ({
            code: data.couponCode,
            qrcode: data.qrString,
            wasUsed: !!data.useDate
        })
    } catch (error) {
        console.log(error)
        throw Error(error)
    }
}

const getUserByCouponCode = (code: string) => {

    const injectCouponCode = (response: any) => Object.assign(response.data, { couponCode: code })

    return request.get(`/coupons/${code}`).then(injectCouponCode)
}


export default {
    getCoupon,
    getUserByCouponCode
}
import { Invite } from '../../../../shared/interfaces/invite.interface';
import { PublicClientApplication } from '@azure/msal-browser';
import { useState } from 'react';
import { inviteApi } from '../api';
import { loginRequest, msalConfig } from '../config/ssoAuthConfig';
import { NavigateFunction } from 'react-router-dom';
import BVUtils from '@bv/utils';

export default function useSSO(navigate: NavigateFunction) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSSO, setIsSSO] = useState<boolean>(false);
  const [sooInfo, setSooInfo] = useState({
    clientId: '',
    cloudPlataform: '',
  });

  async function checkSSO(username: string) {
    setIsSSO(false);
    const invite: Invite = await getCompanySSOProps(username);
    const isSSOEnabled = invite?.isSSOEnabled;
    const clientId = invite?.company?.sso?.clientId;
    if (!invite) {
      return null;
    }

    if ((clientId && invite?.status == 'pending' && isSSOEnabled) || (clientId && isSSOEnabled && invite?.status == 'opened')) {
      setIsSSO(true);
      redirectIfPendingInvite(invite);
      return {
        status: 'pending',
      };
    }
    if ((clientId && isSSOEnabled && invite?.status == 'deleted') || (clientId && isSSOEnabled && invite?.status == 'expired')) {
      BVUtils.toastHandler({ type: 'error', message: 'Convidado expirado ou excluído!' });
      return null;
    }
    if (!invite?.company?.sso || !clientId || !isSSOEnabled) {
      return null;
    }
    setIsSSO(true);
    const cloudPlataform = invite?.company?.sso?.cloudPlataform;
    setSooInfo({
      clientId: clientId,
      cloudPlataform: cloudPlataform,
    });
    return {
      clientId: clientId,
      cloudPlataform: cloudPlataform,
    };
  }
  function redirectIfPendingInvite(invite: Invite) {
    invite && navigate(`/convidado?_i=${invite.id}`);
  }
  async function ssoSignIn(username: string) {
    switch (sooInfo.cloudPlataform) {
      case 'AZURE':
        return await signInAzure(username);
      default:
        return null;
    }
  }

  async function getCompanySSOProps(username?: string) {
    setIsLoading(true);
    try {
      const { data } = await inviteApi.getInviteByEmail(username);
      return data;
    } catch (error) {
      const message = error?.response?.data?.message || error?.message || 'Erro';
      BVUtils.toastHandler({ type: 'error', message });
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  async function signInAzure(username: string) {
    loginRequest.loginHint = username;
    msalConfig.auth.clientId = sooInfo.clientId; // props.clientId
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      try {
        const account = await msalInstance.loginPopup(loginRequest);
        // TODO login with only username | return accessToken and user
        return account;
      } catch (error) {
        console.log(error);
        throw {
          message: error?.message,
          closedModal: true,
        };
      }
    }

    return null;
  }

  return {
    checkSSO,
    ssoSignIn,
    isLoading,
    isSSO,
    sooInfo,
  };
}

export async function ssoSignOut() {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    accounts.forEach((accoun) => {
      const logoutRequest = {
        account: accoun, //msalInstance.getAccountByUsername(username),
        // postLogoutRedirectUri: msalConfig.auth.redirectUri,
      };

      msalInstance.logoutPopup(logoutRequest).then(() => {});
    });
    // temporario => sessionStorage.clear()
    sessionStorage.clear();
  }
}

import {
  IPaginatedPaylod,
  IPaginatedResponse,
} from "../../../../shared/interfaces/request.interface";
import request from "../request";
import { AxiosResponse } from "axios";
import { Plan } from "../../../../shared/interfaces/plan.interface";

type TGetAll = IPaginatedPaylod & {
  name?: string;
  status?: "active" | "inactive";
};
type TBody = {
  name: string;
  status: "active" | "inactive";
  modulesIncluded: Array<string>;
};
const getAll = async ({
  itemsPerPage = 10,
  page = 1,
  name,
  status,
}: TGetAll): Promise<AxiosResponse<IPaginatedResponse<Plan>>> => {
  const config = {
    params: { page, itemsPerPage, name, status },
  };
  return request.get(`/plans`, config);
};

const create = async (data: TBody): Promise<AxiosResponse<{ id: string }>> => {
  return request.post("/plans", data);
};

const update = async (
  id: string,
  data: TBody
): Promise<AxiosResponse<{ id: string }>> => {
  return request.patch(`/plans/${id}`, data);
};

export default {
  getAll,
  create,
  update,
};

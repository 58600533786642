import {
  ICreateSale,
  IGetSales,
  IGetStatistics,
  IResponseCreateSale,
  IResponseEan,
  IResponseGetSales,
  IResponseSalesStatistics,
} from "../../../../shared/interfaces/sales.interface";
import request from "../request";
import { AxiosResponse } from "axios";
import { IPaginatedResponse } from "../../../../shared/interfaces/request.interface";

const getSales = ({
  startDate,
  endDate,
  page = 1,
  itemsPerPage = 10,
}: IGetSales): Promise<
  AxiosResponse<IPaginatedResponse<IResponseGetSales>>
> => {
  const config = {
    params: { startDate, endDate, page, itemsPerPage },
  };

  return request.get("/sales", config);
};

const createSale = (
  body: ICreateSale[]
): Promise<AxiosResponse<IResponseCreateSale>> => {
  return request.post(`/sales`, body);
};

const getSalesStatistics = ({
  startDate,
  endDate,
}: IGetStatistics): Promise<AxiosResponse<IResponseSalesStatistics>> => {
  const config = {
    params: { startDate, endDate },
  };

  return request.get(`/sales/statistics`, config);
};

const getEan = (ean: string): Promise<AxiosResponse<IResponseEan>> => {
  return request.get(`/ean/${ean}`);
};

export default {
  createSale,
  getSales,
  getSalesStatistics,
  getEan,
};

import request from "../request";


function saveProfile(userData) {
  return request.put('/profile', userData)
}

export default {
  saveProfile
}

function formatCPF(data: string): string {
  //retira os caracteres indesejados...
  data.replace(/[^\d]/g, "");

  //realizar a formatação...
  return data.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function formatPhone(phone: string): string {
  if (!phone) return "";
  phone.replace(/[^\d]/g, "");
  return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
}

function formatPriceLocale(price: number): string {
  if (isNaN(this as number)) {
    return "";
  }
  const number = Number(this);
  const formattedNumber = number.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNumber;
}

export { formatCPF, formatPhone, formatPriceLocale };

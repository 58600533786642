import request from '../request'

// TODO Not implemented method in v2 structure
const getCoupon = async () => {
    try {
        const res = await request.get('/v2/coupon')
        const data = res.data

        return ({
            code: data.couponCode,
            qrcode: data.qrString,
            wasUsed: !!data.useDate
        })
    } catch (error) {
        console.log(error)
        throw Error(error)
    }
}

const getUserByCouponCode = (couponCode: string) => {
    return request.get(`/v2/coupons/code/${couponCode}`);
}


export default {
    getCoupon,
    getUserByCouponCode
}